import React, { useState } from 'react';
import YusraLogo from '../../assets/Yusra-Horizontal-Logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './custom_navbar.css';
import Button from 'react-bootstrap/Button';


const Custom_Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

  return (
     <Navbar bg="light" expand="lg" className='navbar'>
      <Container>
        <Navbar.Brand href="#home"> <img src={YusraLogo} alt="logo" className='img-nav'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/home#about">About</Nav.Link>
            {/* <NavDropdown title="Case Studies" id="basic-nav-dropdown">*/}
            {/*  <NavDropdown.Item href="/comingsoon">All Case Studies </NavDropdown.Item>*/}
            {/*    /!* FIXME change and uncomment below when these pages have been made *!/*/}
            {/*     /!* <NavDropdown.Divider />*/}
            {/*     <NavDropdown.Item href="#action/3.1">CloudAI</NavDropdown.Item>*/}
            {/*     <NavDropdown.Item href="#action/3.1">Hub</NavDropdown.Item> *!/*/}
            {/*</NavDropdown>*/}
              <Nav.Link href="/home#hub">The Hub</Nav.Link>
              <Nav.Link href="/comingsoon">CloudAI</Nav.Link>
          </Nav>
            <Nav>
                <div>
                <Button href="/comingsoon">SignUp</Button>{' '}
                <Button variant="secondary" href="/comingsoon">Sign In</Button>{' '}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Custom_Navbar