import React from 'react';
import {Brand, Feature} from '../../components';
import './hub.css';
import { team_img } from '../../containers/imports';

const featuresData = [
  {
    title: 'Premium Access',
    text: 'Gain access to a large pool of skilled engineering talent. These professionals have been vetted and screened, so you can be confident that they have the necessary qualifications to meet your project\'s requirements.'
  },
  {
    title: 'Time Saving',
    text: 'Streamlined process by avoiding the need to search through resumes, screening, and conducting interviews. Our Hub simplifies the process where you have the options to easily search and connect with the right professionals. '
  },
  {
    title: 'Cost Effective',
    text: 'Find engineering talent by comparing rates to select the right professionals based on your budget and project needs. Additionally, avoid overhead costs associated with hiring full-time employees.'
  },
  {
    title: 'Flexibility',
    text: 'Our Hub offers flexibility in terms of project length and scope. We ensure the best experience by offering replaceable consultants at no cost. This is particularly beneficial for startups or companies with fluctuating project demands.'
  },

]


const Hub = () => {
  return (
    <div className='yusra_hub section__padding-features' id='hub'>
      <div className='yusra_hub-heading'>
        <div className='img_holder'>
          <img className='binary_flow_4k' src={team_img} alt='blog01' ></img>
        </div>
      </div>

      <div className='yusra_hub-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Hub