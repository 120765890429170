import React from 'react';
import './partners.css';
import Marquee from "react-fast-marquee";
import { cvssvg,aetnasvg,unitedsvg,alphaNBsvg,fitterlawsvg,comprescuesvg,luxupsvg,papasvg,sandboxnbsvg,evolutionsvg, gmsvg,techm, cbts } from '../imports';

const Partners = () => {
    return (

        <div id='partner_section'>
            <div className='yusra_partner_card' id='yusra_partner_card'>
                <h1 className="gradient__text">Companies Just Like Yours</h1>
                <div>
                <Marquee speed={50} gradient={false}>
                    
                    <div className='yusra_partner'>
                        
                        <div>
                            <img src={cvssvg} alt='cvs' />&nbsp;
                        </div>
                        <div>
                        &nbsp; <img src={aetnasvg} alt='aetna' />
                        </div>
                        <div>
                            <img src={unitedsvg} alt='united' />&nbsp;
                        </div>
                        <div>
                            <img src={techm} alt='techm' />
                        </div>
                        <div>
                            <img src={cbts} alt='cbts' />
                        </div>
                        <div>
                            &nbsp;<img src={evolutionsvg} alt='evolutionsvg' />
                        </div>
                        <div>
                            <img src={fitterlawsvg} alt='fitterlawsvg' />
                        </div>
                        <div>
                            <img src={alphaNBsvg} alt='alphaNBsvg' />
                        </div>
                        <div>
                            <img src={comprescuesvg} alt='comprescuesvg' />
                        </div>
                        <div>
                            <img src={luxupsvg} alt='luxupsvg' />
                        </div>
                        <div>
                            <img src={papasvg} alt='papasvg' />
                        </div>
                        <div>
                            <img src={gmsvg} alt='gmsvg' />
                        </div>
                        <div>
                            <img src={sandboxnbsvg} alt='sandboxnbsvg' />
                        </div>
                    </div>
                    
                </Marquee>
                
                </div>

            </div>
        </div>


    )
};


export default Partners