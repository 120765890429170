import React from 'react';
import { Custom_Navbar } from '../../components';
import './hub.css';

const Hub = () => {
    return (
        <div>
            <Custom_Navbar />
            <h1>Hub</h1>
        </div>
    )
}

export default Hub;