import React from 'react';
import './whatYusra.css';
import { Feature } from '../../components';
import cvssvg from '../../assets/cvs-logo.svg';

const WhatYusra = () => {
  return (
    <div className='yusra__whatyusra' id='about'>
      <div className='yusra__whatyusra-feature'>
        <Feature title="Creating Strong Teams"/>
        <Feature title="Monitoring Your Cloud " />
        <Feature title="Custom Scalable Development" />
        {/* <div className='yusra__space'></div> */}
      </div>
      
      <div className='yusra__whatyusra-heading'> 
        <h1 className='gradient__text'>Securing & Optimizing Your Business One Solution At A Time</h1>
      </div>
      <div className='yusra__whatyusra-container'>
        <Feature title="Recruitment" text="Our unique approach involves getting to know our clients and candidates on a personal level to ensure the right fit for both parties. We believe that a successful recruitment process goes beyond just matching skills and experience, but also aligning values and goals."/>
        <Feature title="Cloud Remediation" text="In today's rapidly evolving digital landscape, safeguarding your cloud environment is more crucial than ever. Introducing our comprehensive Cloud Remediation solution, designed to empower your organization with advanced security and peace of mind."/>
        <Feature title="Fullstack Development" text="Our team of diversely skilled developers can create custom solutions that meet the unique needs of our clients. This includes using the latest technologies and frameworks to create scalable, secure, and efficient software applications."/>
      </div>
    </div>
  )
}

export default WhatYusra