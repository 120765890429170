import React from 'react';
import './terms.css'
import {Link} from "react-router-dom";

//FIXME format the html
const Terms = () => {
    return (
        <div className='tos'>
            <h1>Terms of Service for https://www.yusra-io.com  </h1>
                        <center><Link to='/home'>Take Me Home</Link></center>


<p>Website Terms of Use</p>

<p>Last Modified on: March 13, 2023</p>
<br></br>
<p>TERMS AND CONDITIONS OF USE</p> 
<br></br>
Welcome to our website. This site is maintained as a service to our customers. By using this site, you agree to comply with and be bound by the following terms and conditions of use. Please review these terms and conditions carefully. If you do not agree to these terms and conditions, you should not use this site. 

1. Agreement. This Term of Use agreement ("the "Agreement") specifies the Terms and Conditions for access to and use of Yusra Consulting (the "Site") and describe the terms and conditions applicable to your access of and use of the Site. This Agreement may be modified at any time by Yusra Consulting upon posting of the modified Agreement. Any such modifications shall be effective immediately. You can view the most recent version of these terms at any time at https://www.Yusra Consulting.com. Each use by you shall constitute and be deemed your unconditional acceptance of this Agreement. 

2. Privacy. Your visit to our site is also governed by our Privacy Policy. Please review our Privacy Policy at 

https://www.Yusra Consulting.com/privacy-policy/. 

3. Ownership. All content included on this site is and shall continue to be the property of Yusra Consulting or its content suppliers and is protected under applicable copyright, patent, trademark, and other proprietary rights. Any copying, redistribution, use or publication by you of any such content or any part of the Site is prohibited, except as expressly permitted in this Agreement. Under no circumstances will you acquire any ownership rights or other interest in any content by or through your use of this Site. 

4. Intended Audience. This website is not intended for any children under the age of 16. 

5. Trademarks. The Site loge, phrase, word, symbol, and others are either trademarks or registered trademarks of Yusra Consulting. Other product and company names mentioned on this Site may be trademarks of their respective owners. 

6. Site Use. Yusra Consulting grants you a limited, revocable, nonexclusive license to use this site solely for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works, or other use. You agree not to copy materials on the site, reverse engineer or break into the site, or use materials, products or services in violation of any law. The use of this website is at the discretion of Yusra Consulting and Yusra Consulting may terminate your use of this website at any time. 

7. Compliance with Laws. You agree to comply with all applicable laws regarding your use of the website. You further agreed that information provided by you is truthful and accurate to the best of your knowledge. 

8. Indemnification. You agree to indemnify, defend and hold Yusra Consulting and our partners, employees, and affiliates, harmless from any liability, loss, claim and expense, including reasonable attorney's fees, related to your violation of this Agreement or use of the Site. 9. Disclaimer. THE INFORMATION ON THIS SITE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS. YOU AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK. Yusra Consulting DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SITE SHALL BE TO DISCONTINUE USING THE SITE. 10. Limitation of Liability. UNDER NO CIRCUMSTANCES WILL Yusra Consulting BE LIABLE OR RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE AND/OR CONTENT IS TO CEASE ALL OF YOUR SITE USE. 

You may have additional rights under certain laws (including consumer laws) which do not allow the exclusion of implied warranties, or the exclusion or limitation of certain damages. If these laws apply to you, the exclusions or limitations in this Agreement that directly conflict with such laws may not apply to you. 

11. Use of Information. Yusra Consulting reserves the right, and you authorize us, to use and assign all information regarding site uses by you and all information provided by you in any manner consistent with our Privacy Policy. 

12. Copyrights and Copyright Agent. If you believe your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have otherwise been violated, please provide a notice containing all of the following information to our Copyright Agent:

07/13/2021 1/2 

Terms of Service for https://www.Yusra Consulting.com 

a. An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest; 

b. A description of the copyrighted work that you claim has been infringed; 

c. A description of where the material that you claim is infringing is located on the Site; 

d. Your address, telephone number, and e-mail address; 

e. A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and 

f. A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf. 

Our Copyright Agent for Notice of claims of copyright infringement on the Site is Fitter Law, LLC, who can be reached as follows: By Web: https://fitterlaw.com/copyright-claim-form/ 

By Mail:  

Yusra Consulting 

c/o Fitter Law, LLC 

150 S. Wacker Drive, Suite 2400 

Chicago, IL 60606



13. Applicable Law. You agree that the laws of the state of Illinois, without regard to conflicts of laws provisions will govern these Terms and Condition of Use and any dispute that may arise between you and Yusra Consulting or its affiliates. 

14. Severability. If any provision of this Agreement shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect. 

15. Waiver. The failure of Yusra Consulting to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by Yusra Consulting must be in writing and signed by an authorized representative of Yusra Consulting. 16. Termination. Yusra Consulting may terminate this Agreement at any time, with or without notice, for any reason. 17. Relationship of the Parties. Nothing contained in this Agreement or your use of the Site shall be construed to constitute either party as a partner, joint venturer, employee or agent of the other party, nor shall either party hold itself out as such. Neither party has any right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions. 

18. Entire Agreement. This Terms of Use constitutes the entire agreement between you and Yusra Consulting and governs the terms and conditions of your use of the Site, and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Yusra Consulting with respect to this Site. Notwithstanding the foregoing, you may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use the website.  Yusra Consulting may revise this Terms of Use at any time by updating this Agreement and posting it on the Site. Accordingly, you should visit the Site and review the Terms of Use periodically to determine if any changes have been made. Your continued use of this website after any changes have been made to the Terms of Use signifies and confirms your acceptance of any such changes or amendments to the Terms of Use. 

19. Contact Information.
<p>Yusra Consulting </p>
<p>Chicago, IL</p>
        </div>
    )
}

export default Terms;