import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';


//This means entire app component will be rendered inside ID 'root' index.html
const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Deprecated
// ReactDOM.render(<App />, document.getElementById('root'));
