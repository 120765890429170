import React, { useState } from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import abstract_1 from '../../assets/abstract_1.png';
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import HubspotForm from "react-hubspot-form";
import Button from "react-bootstrap/Button";

const Header = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div className="yusra__header section__padding" id="home">
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="flex"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Let's Get To Know Each Other</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <HubspotForm
                portalId='24207010'
                formId='4c4d8d0b-3f1f-4b6c-a593-0f6b4aa99b84'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
      />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="yusra__header-content">
        <h1 className="yusra__punchline-text">Next Generation Of AI Cloud Security Solutions</h1>

        <p>Supercharge your business with our tailored solutions. Our expert team is dedicated to helping you
            succeed, with a focus on strong team culture and advanced technology. Contact us now to learn how we can
            help transform your business and give you the edge you need to succeed.</p>

        <div className="yusra__header-content__input">
          {/*<input type="email" placeholder="Your Email Address"></input>*/}
          <button type="button"  onClick={handleShow}>Get Started</button>
        </div>

        <div className="yusra__header-content__people">
          <img src={people} alt="people" />
          <p>Ensuring Your Company's Safety on the Cloud to Keep Your Business Protected</p>
        </div>


      </div>
      <div className="yusra__header-image">
          <img src={ai} alt="ai"/>
        </div>
    </div>
  )
}

export default Header