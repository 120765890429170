import React from 'react';
import { splunk_round, react, python, figma, aws, azure } from '../../components/imports';
import './brand.css';


const Brand = () => {
  return (
    <div>
      <div className='yusra_brand-techs'>
        <h1 className="gradient__text"> Discover Top Engineering Talent Using Our Hub</h1>
      </div>
      <div className='yusra_brand'>

        <div>
          <img src={react} alt='react' />
        </div>
        <div>
          <img src={azure} alt='azure' />
        </div>
        <div>
          <img src={aws} alt='aws' />
        </div>
        <div>
          <img src={python} alt='python' />
        </div>
        <div>
          <img src={splunk_round} alt='splunk' />
        </div>
      </div>
    </div>

  )
}

export default Brand