import React from 'react';
import { Custom_Navbar } from '../../components';
import './cloudai.css';

const CloudAI = () => {
    return (
        <div>
            <Custom_Navbar />
            <h1>Cloud AI</h1>
        </div>
    )
}


export default CloudAI;