import React from 'react';
import { Hub, Footer, Header, WhatYusra } from '../../containers';
import { Brand, Custom_Navbar, Partners } from '../../components';
import './home.css';

const Home = () => {
    return (
        <div>
            <Custom_Navbar />
            <Header />
            <Partners />
            <WhatYusra />
            <Brand/>
            <Hub />
            <Footer />
        </div>
    )
}


export default Home;