import React, {useState} from 'react'
import { Custom_Navbar } from '../../components';
import HubspotForm from 'react-hubspot-form'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './css/main.css';
import {Footer} from "../../containers";

const ComingSoon = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  return (
    <div class="site-wrapper-inner coming_soon_body" >
        <Modal
    show={show}
    onHide={handleClose}
    backdrop="flex"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Let's Get To Know Each Other</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <HubspotForm
            portalId='24207010'
            formId='4c4d8d0b-3f1f-4b6c-a593-0f6b4aa99b84'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
  />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
      <div class="cover-container">
        <div class="masthead clearfix">
        <Custom_Navbar />
          <div class="inner">
            <h3 class="masthead-brand">Coming Soon</h3>
          </div>
        </div>      
        <div class="inner cover">
          <h1 class="cover-heading">The Adventure Begins</h1>
          <p class="lead cover-copy">Hold tight as we get our working robots together and produce the most astonishing product ever.</p>
        </div>
  <Footer />
    </div>

  </div>
  )
}

export default ComingSoon;