import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import * as Pages from './pages';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Pages.Home />} />
        <Route path="/about" element={<Pages.About />} />
        <Route path="/casestudies" element={<Pages.CaseStudies />} />
        <Route path="/cloudai" element={<Pages.CloudAI />} />
        <Route path="/hub" element={<Pages.Hub />} />
        <Route path="/privacypolicy" element={<Pages.Privacy />} />
        <Route path="/terms" element={<Pages.Terms/>}/>
        <Route path="/comingsoon" element={<Pages.ComingSoon/>} />

      </Routes>
    </BrowserRouter>
  )
}

export default App