import React from 'react';
import { Custom_Navbar } from '../../components';
import './casestudies.css';

const CaseStudies = () => {
    return (
        <div>
            <Custom_Navbar />
            <h1>Case Studies</h1>
        </div>
    )
}


export default CaseStudies;