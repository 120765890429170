import React, {useState} from 'react';
import YusraLogo from '../../assets/Yusra-Horizontal-Logo.svg';
import MBE from '../../assets/mbe.svg'
import { Link } from 'react-router-dom';
import HubspotForm from 'react-hubspot-form'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';

import { Privacy, Terms } from '../../pages';

const Footer = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (

    <div className="yusra_footer section__padding">

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="flex"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Let's Get To Know Each Other</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <HubspotForm
                portalId='24207010'
                formId='4c4d8d0b-3f1f-4b6c-a593-0f6b4aa99b84'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
      />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    <div className="yusra_footer_heading">
      <h1 className="gradient__text">Join the innovative minds and cutting-edge companies driving IT forward with us</h1>
    </div>

    <div className="yusra_footer_btn">
      <button type="button" id="early-access-btn"  onClick={handleShow}>Request Early Access</button>
    </div>
    

    <div className="yusra_footer_links">
      <div className="yusra_footer_links_logo">
        <img src={YusraLogo} alt="yusra_logo" />
      </div>
      <div className="yusra_footer_links_div">
        <h4>Company</h4>
        <p>DUNS Code: 082221612</p>
        <Link to="/privacypolicy" className="link_class2">Privacy Policy</Link>
        <Link to="/terms" className="link_class2">Terms & Conditions</Link>
      </div>

      <div className="yusra_footer_links_div">
        <h4>NAICS Codes</h4>
        <p>541511, 541430,</p>
        <p>541512, 541611,</p>
        <p>541720, 926110,</p>
        <p>711510</p>
      </div>

      
      <div className="yusra_footer_links_div">
        <h4>Get in touch</h4>
        <p>848 Dodge Ave Suite 302</p>
        <p>Evanston, IL 60202</p>
        <p>info@yusra.io</p>
        <img src={MBE} alt="mbe_logo"  className="mbe_logo"/>
      </div>
    </div>

    <div className="yusra_footer_copyright">
      <p>@2018 Yusra LLC. All rights reserved.</p>

    </div>
    
  </div>
  )
}

export default Footer