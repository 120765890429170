import React from 'react';
import './privacy.css'
import {Link} from 'react-router-dom'



const Privacy = () => {
    return (
        <div className='tos'>
            <h1>Website Privacy Policy for https://www.yusra-io.com </h1>
            <center><Link to='/home'>Take Me Home</Link></center>
            

    <p>Website Privacy Policy </p>

    <p>Last modified: March 13, 2023 </p>
    <br></br>
    <p>Introduction </p>
    <br></br>

    AlphaNB ("Company" or "We") respects your privacy and is committed to protecting it through our compliance with this policy.

    This policy describes the types of information we may collect from you or that you may provide when you visit the website https://www.alphanb.com (our "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.

    This policy applies to information we collect:

    On this Website.

    In email, text, and other electronic messages between you and this Website.

    Through mobile and desktop applications you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.

    When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.

    It does not apply to information collected by:

    Us offline or through any other means, including on any other website operated by Company or any third party[ (including our affiliates and subsidiaries)]; or

    Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.

    Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.

    Children Under the Age of 16

    Our Website is not intended for children under 16 years of age. No one under age 16 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or through any of its features, register on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us.

    Information We Collect About You and How We Collect It

    We collect several types of information from and about users of our Website, including information:

    By which you may be personally identified, such as name, postal address, e-mail address, telephone number or other personal information/any other identifier by which you may be contacted online or offline ("personal information");

    That is about you but individually does not identify you; and/or

    About your internet connection, the equipment you use to access our Website, and usage details.

    We collect this information:

    Directly from you when you provide it to us.

    07/13/2021 1/4

    Website Privacy Policy for https://www.alphanb.com

    Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.

    From third parties, for example, our business partners.

    Information You Provide to Us

    The information we collect on or through our Website may include:

    Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.

    Records and copies of your correspondence (including email addresses), if you contact us.

    Your responses to surveys that we might ask you to complete for research purposes.

    Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.

    Your search queries on the Website.

    You also may provide information to be published or displayed (hereinafter, "posted") on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.

    Information We Collect Through Automatic Data Collection Technologies

    As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:

     Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.

    Information about your computer and internet connection, including your IP address, operating system, and browser type.

    The information we collect automatically may be statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:

    Estimate our audience size and usage patterns.

    Store information about your preferences, allowing us to customize our Website according to your individual interests. Speed up your searches.

    Recognize you when you return to our Website.

    The technologies we use for this automatic data collection may include:

    Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.

    Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.

    Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or [opened an email] and for other related website statistics (for example, recording the popularity of certain website content and verifying system and

    07/13/2021 2/4

    Website Privacy Policy for https://www.alphanb.com

    server integrity).

    We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.

    Third-Party Use of Cookies and Other Tracking Technologies

    Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.

    We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.

    How We Use Your Information

    We use information that we collect about you or that you provide to us, including any personal information:

    To present our Website and its contents to you.

    To provide you with information, products, or services that you request from us.

    To fulfill any other purpose for which you provide it.

    To provide you with notices about your [account/subscription], including expiration and renewal notices.

    To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.

    To notify you about changes to our Website or any products or services we offer or provide though it.

    To allow you to participate in interactive features on our Website.

    In any other way we may describe when you provide the information.

    For any other purpose with your consent.

    We may also use your information to contact you about our own and third-parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data (registration form) or adjust your user preferences in your account profile.

    We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.

    Disclosure of Your Information

    We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We may disclose personal information that we collect or you provide as described in this privacy policy:

    To our subsidiaries and affiliates.

    To contractors, service providers, and other third parties we use to support our business.

    To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of AlphaNB's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by AlphaNB about our Website users is among the assets transferred.

    To third parties to market their products or services to you if you have not opted out of these disclosures.

    To fulfill the purpose for which you provide it. For example, if you give us an email address to use the "email a friend" feature of our Website, we will transmit the contents of that email and your email address to the recipients.

    For any other purpose disclosed by us when you provide the information.

    07/13/2021 3/4

    Website Privacy Policy for https://www.alphanb.com

    With your consent.

    We may also disclose your personal information:

    To comply with any court order, law, or legal process, including to respond to any government or regulatory request. To enforce or apply our terms of use https://www.alphanb.com/terms-of-use/ and other agreements, including for billing and collection purposes.

    If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of AlphaNB, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.

    Choices About How We Use and Disclose Your Information

    We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:

    Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly. Promotional Offers from the Company. If you do not wish to have your [email address/contact information] used by the Company to promote our own or third parties' products or services, you can opt-out by checking the relevant box located on the form on which we collect your data (the order form, registration form, or opt-out form). If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.

    We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.

    Changes to Our Privacy Policy

    It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Website home page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.

    Contact Information

    To ask questions or comment about this privacy policy and our privacy practices, contact us at info@yusra.io:

            </div>
        )
    }

export default Privacy;